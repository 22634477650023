import React, { useEffect, useRef } from "react";
import * as THREE from "three";

// Vertex Shader
const particleVertex = `
  attribute float scale;
  uniform float uTime;

  void main() {
    vec3 p = position;
    float s = scale;

    // Create a spiral effect
    float angle = atan(p.x, p.z); // Angle based on x and z position
    float radius = length(vec2(p.x, p.z)); // Distance from the origin in the x-z plane

    // Apply spiral transformation
    float randomOffset = (sin(p.y * 10.0 + uTime) * 0.5) + (cos(p.x * 10.0 + uTime) * 0.5);
    p.y += (sin(angle + uTime) * 0.5) + (cos(radius + uTime) * 0.1) * 2.0 + randomOffset;
    p.x += (sin(p.y + uTime) * 0.5) + randomOffset;
    p.z += (cos(angle + uTime) * 0.5) + randomOffset;

    s += (sin(angle + uTime) * 0.5) + (cos(radius + uTime) * 0.1) * 2.0 + randomOffset;

    vec4 mvPosition = modelViewMatrix * vec4(p, 1.0);
    gl_PointSize = s * 15.0 * (1.0 / -mvPosition.z);
    gl_Position = projectionMatrix * mvPosition;
  }
`;

// Fragment Shader with Opacity and Gray Color
const particleFragment = `
  uniform float uOpacity;

  void main() {
    gl_FragColor = vec4(0.5, 0.5, 0.5, uOpacity); // Set color to gray
  }
`;

const ThreeScene = () => {
  const canvasRef = useRef();

  useEffect(() => {
    const config = {
      canvas: canvasRef.current,
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
      aspectRatio: window.innerWidth / window.innerHeight,
    };

    let camera, scene, renderer, particleGeometry, particleMaterial, particles;

    const initCamera = () => {
      camera = new THREE.PerspectiveCamera(75, config.aspectRatio, 0.01, 1000);
      camera.position.set(0, 6, 5);
    };

    const initScene = () => {
      scene = new THREE.Scene();
    };

    const initRenderer = () => {
      renderer = new THREE.WebGLRenderer({
        canvas: config.canvas,
        antialias: true,
      });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(config.winWidth, config.winHeight);
      renderer.setClearColor(0xffffff); // Set background color to white
    };

    const initParticles = () => {
      const gap = 0.3;
      const amountX = 200;
      const amountY = 200;
      const particleNum = amountX * amountY;
      const particlePositions = new Float32Array(particleNum * 3);
      const particleScales = new Float32Array(particleNum);
      let i = 0,
        j = 0;

      for (let ix = 0; ix < amountX; ix++) {
        for (let iy = 0; iy < amountY; iy++) {
          const x = ix * gap - (amountX * gap) / 2;
          const z = iy * gap - (amountY * gap) / 2;
          // Initial spiral position
          particlePositions[i] = x;
          particlePositions[i + 1] = 0;
          particlePositions[i + 2] = z;
          particleScales[j] = 1;
          i += 3;
          j++;
        }
      }

      particleGeometry = new THREE.BufferGeometry();
      particleGeometry.setAttribute(
        "position",
        new THREE.BufferAttribute(particlePositions, 3)
      );
      particleGeometry.setAttribute(
        "scale",
        new THREE.BufferAttribute(particleScales, 1)
      );

      particleMaterial = new THREE.ShaderMaterial({
        transparent: true,
        vertexShader: particleVertex,
        fragmentShader: particleFragment,
        uniforms: {
          uTime: { value: 0.5 },
          uOpacity: { value: 0.5 },
        },
      });

      particles = new THREE.Points(particleGeometry, particleMaterial);
      scene.add(particles);
    };

    const render = () => {
      camera.lookAt(scene.position);
      renderer.render(scene, camera);
    };

    const animate = () => {
      particleMaterial.uniforms.uTime.value += 0.01; // Slower transition
      requestAnimationFrame(animate);
      render();
    };

    const onResize = () => {
      config.winWidth = window.innerWidth;
      config.winHeight = window.innerHeight;
      camera.aspect = config.winWidth / config.winHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(config.winWidth, config.winHeight);
    };

    initCamera();
    initScene();
    initRenderer();
    initParticles();
    animate();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
      renderer.dispose();
    };
  }, []);

  return <canvas ref={canvasRef} className="canvass" />;
};

export default ThreeScene;
