import React from "react";
import logoWhite from "../../assets/logo white.svg";
import {
  CopyrightIcon,
  FacebookIconCircleWhite,
  InstagramIconCircleWhite,
  LinkedinIconCircleWhite,
  TwitterIconCircleWhite,
} from "../../assets/svgs";
import twitter from "../../assets/svgs/twitter-logo-2.svg";
import kenya from "../../assets/kenya.svg";
import nigeria from "../../assets/nigeria.svg";

export default function FooterComp() {
  return (
    <div className=" py-5 footer-area">
      <div className="container">
        <div className="col-md-9 mx-auto">
          <div className="row">
            <div className="col-md-6">
              <img src={logoWhite} alt="" width="150px" className="mb-3" />

              <p className="me-3">Countries Supported</p>
              <div className="d-flex">
                <a href="#" target="_blank ">
                  <span className="me-3">
                    <img src={nigeria} />
                  </span>
                </a>

                <a
                  href="https://www.linkedin.com/company/clars/about/"
                  target="_blank"
                >
                  <img src={kenya} />{" "}
                </a>
              </div>
              <br />
              <div className="d-flex">
                <a href="#" target="_blank">
                  <span
                    className="me-2 twitter"
                    style={{
                      borderRadius: "50%",
                      border: "1.5px solid white",
                      display: "inline-block",
                      padding: "2px",
                    }}
                  >
                    <img
                      src={twitter}
                      style={{ width: "35px", borderRadius: "50%" }}
                    />
                  </span>
                </a>

                {/* <a href="#" target="_blank">
                  <span className="me-2">{FacebookIconCircleWhite}</span>
                </a> */}
                {/* <a href="#" target="_blank">
                  <span className="me-2">{InstagramIconCircleWhite}</span>
                </a> */}
                <a
                  href="https://www.linkedin.com/company/clars/about/"
                  target="_blank"
                >
                  <span className="me-2">{LinkedinIconCircleWhite}</span>
                </a>
              </div>
            </div>
            <div className="col-md-6 footer-link">
              <div className="mt-4 ">
                <small className="">Company</small>
              </div>
              {/* <a href="/about">
                <p className="pt-4 ">About CLARS</p>
              </a> */}
              <a href="/contact">
                {" "}
                <p className="pt-3">Contact Us</p>
              </a>
            </div>
          </div>

          <div className="d-flex justify-content-between border-top mt-5 text-center pt-3 me-3 copy ">
            <a href="#" className="link">
              <p>Privacy Policy</p>
            </a>
            <p className=" d-flex align-items-center justify-content-center">
              <span className="me-2">{CopyrightIcon}</span>
              CLARS {new Date().getFullYear()}
            </p>
            <a href="#" className="link">
              <p>Terms and Conditions</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
