import React, { useEffect, useRef, useState } from "react";
import NavbarComp from "../../components/navbar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gif from "../../assets/clarss8.gif";
import ThreeScene from "../../components/Three";
import empowerImg from "../../assets/empower-card-img.png";
import navigateImg from "../../assets/navigate-card-img.png";
import bank from "../../assets/bank.png";
import prembly from "../../assets/Prembly-logo 1.png";
import vfd from "../../assets/VFD-Group-Logo-PNG.png";
import intelytic from "../../assets/logo_dark.png";
import {
  BlackArrowCircleRight,
  DollarCircleIconBlack,
  EyeIconBlack,
  MessageTextIconBlack,
  SliderIconOne,
  SliderIconThree,
  SliderIconTwo,
  WhiteArrowCircleRight,
} from "../../assets/svgs";
import FooterComp from "../../components/footer";
import FAQSection from "./FAQ";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Row } from "react-bootstrap";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2.0,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Card = ({
  bgColor,
  imgSrc,
  title,
  description,
  btnText,
  btnLink,
  btnIcon,
  style = {},
}) => (
  <div className="fit">
    <div className=" me-4">
      <div
        className={`card-text card text-${
          bgColor === "#4161DF" ? "white" : "dark"
        }`}
        style={{
          backgroundColor: bgColor,
          ...style,
        }}
      >
        <div className="card-body d-flex flex-column">
          <h3>{title}</h3>
          <p className="flex-grow-1">{description}</p>
          <br />
          <a
            className={`btn btn-${
              bgColor === "#4161DF" ? "white-outline mt-5" : "black-outline"
            } atag mt-auto `}
            href={btnLink}
            target="_blank"
            rel="noreferrer"
            style={{ width: "fit-content" }}
          >
            {btnText}
            <span className="ms-3">{btnIcon}</span>
          </a>
        </div>
        <br />

        <div className="d-flex justify-content-center mt-3">
          <img
            src={imgSrc}
            alt={title}
            className="w-90"
            style={{ maxWidth: "100%", height: "150px" }}
          />
        </div>
      </div>
    </div>
  </div>
);

const SliderCard = ({ icon, title, description }) => (
  <div
    className="col slidercon"
    style={{ paddingLeft: "10px", paddingRight: "10px", maxWidth: "570px" }}
  >
    <div className="card bg-light border-0 rounded-4">
      <div className="card-body">
        <span>{icon}</span>
        <h4 className="mt-3">{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  </div>
);

const Feature = ({ icon, title, description }) => (
  <div className="col-md-4 text-start px-md-4">
    <span>{icon}</span>
    <h5 className="mt-3">{title}</h5>
    <p>{description}</p>
  </div>
);
export default function HomePage() {
  const [scrollProgress, setScrollProgress] = useState(0);
  const imageRef = useRef(null);
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const sectionRect = sectionRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Start when the section enters the viewport
        const start = sectionRect.top - windowHeight;
        const end = sectionRect.bottom - windowHeight;

        // Calculate scroll progress (0 to 1)
        const progress = Math.max(
          0,
          Math.min(1, (window.scrollY - start) / (end - start))
        );
        setScrollProgress(progress);
      }
    };

    // Run handleScroll once on load to ensure correct initial state
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const calculateImageTransform = () => {
    // Initial values
    const rotateX = 80 - scrollProgress * 80; // Start at 90 degrees (lying down) and rotate to 0 (upright)
    const translateY = 100 - scrollProgress * 100; // Start below and move up
    const scale = 0.5 + scrollProgress * 0.5; // Start smaller and scale up

    return {
      transform: `perspective(1000px) rotateX(${rotateX}deg) translateY(${translateY}%) scale(${scale})`,
      transition: "transform 0.5s ease-out",
    };
  };

  return (
    <>
      <NavbarComp />
      <div className="homepage-container">
        <section className="hero-area" ref={sectionRef}>
          <div className="container">
            <div className="col-md-8 mx-auto text-center pt-2 content">
              <h1 className="py-2">Automate Your Lending Process With CLARS</h1>
            </div>
            <div className="col-md-7 mx-auto text-center pt-2 content">
              <h5>
                CLARS elevates lending operations through technology designed to
                automate and manage collections; creating sustainable financial
                growth and efficiency for loan providers.
              </h5>
            </div>
          </div>
          <div className="background">
            <ThreeScene />
          </div>

          <div
            className="hero-image-wrapper"
            ref={imageRef}
            style={{
              position: "sticky",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={gif}
              alt="Hero"
              className="hero-image"
              style={{
                ...calculateImageTransform(),
                transition: "transform 0.5s ease-out, 0.5s ease-out",
              }}
            />
          </div>
        </section>

        <section className="section3 py-5 ">
          <Row className="d-flex justify-content-center align-center  align-items-center mx-0 roww">
            <Col
              className="py-2 card-2 justify-content-center  align-items-center"
              xs={12}
            >
              <Col>
                <Card
                  bgColor="#4161DF"
                  imgSrc={empowerImg}
                  title="One Tool"
                  description="Only one software is needed to automate your loan collections, customer risk scoring, and loan management."
                  btnText="Get Started"
                  btnLink="http://app.useclars.com/"
                  btnIcon={WhiteArrowCircleRight}
                />
              </Col>
              <Col className="my-4">
                <Card
                  bgColor="#B0E8C7"
                  imgSrc={navigateImg}
                  title="No Paperwork Needed"
                  description="All debit mandates, approvals, verification are all completed in clicks."
                  btnText="Sign Up"
                  btnLink="http://app.useclars.com/"
                  btnIcon={BlackArrowCircleRight}
                />
              </Col>
            </Col>

            <Col className="d-flex align-items-center card-2" xs={12}>
              <Card
                bgColor="#ffffff"
                imgSrc={navigateImg}
                title="Secure an advanced risk scoring"
                description="Each borrower is scored against most important dataset. Customization is also super easy."
                btnText="Sign Up"
                btnLink="http://app.useclars.com/"
                btnIcon={BlackArrowCircleRight}
              />
            </Col>
          </Row>
        </section>

        <section className="py-5">
          <div className="container">
            <div className="col-md-8 mx-auto">
              <div className="row">
                <div className="col-md-6">
                  <h1>Why CLARS</h1>
                </div>
                <div className="col-md-6">
                  <p>
                    CLARS streamlines loan collections by integrating data for
                    automatic payments, while optimizing the entire lending
                    process ensuring efficiency and business growth for loan
                    providers
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="col-md-10 ms-auto">
              <div className="py-5">
                <Slider {...settings}>
                  <SliderCard
                    icon={SliderIconOne}
                    title="Risk Scoring"
                    description="CLARS integrates data from Credit Bureaus, Telecom records, and Bank accounts to accurately assess borrower risk & create a full financial profile that facilitates automatic debits for timely payment collection."
                  />
                  <SliderCard
                    icon={SliderIconTwo}
                    title="Loan Collection"
                    description="Loan Providers can gain full visibility into all digital bank accounts linked to a BVN for both debtors and guarantors to process automations & ensure timely collections."
                  />
                  <SliderCard
                    icon={SliderIconThree}
                    title="Loan Approval"
                    description="Loan providers are able to automate the loan approval process, streamlining the end-to-end process from pre-qualification to final approval."
                  />
                  <SliderCard
                    icon={SliderIconThree}
                    title="Cash Disbursement Management (Beta)"
                    description="CLARS eliminates human error in disbursements, ensuring they are handled with greater efficiency and accuracy."
                  />
                </Slider>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-light py-5 clarsUpdate">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h2>
                  Clars Provides a Robust and Reliable Method for Automatic
                  Payment Collections.
                </h2>
              </div>
              <div className="row g-4 justify-content-center px-md-5">
                <Feature
                  icon={DollarCircleIconBlack}
                  title="Timely Loan Reclaims"
                  description="Facilitate automatic debits from any of the borrower’s linked bank accounts, and receive your dues promptly and efficiently."
                />
                <Feature
                  icon={MessageTextIconBlack}
                  title="Informed Lending Decisions"
                  description="Access a borrower's comprehensive financial profile and consolidated view of a borrower’s financial standing."
                />
                <Feature
                  icon={EyeIconBlack}
                  title="Credit Visibility"
                  description="Ascertain a borrower's credit behaviour and expected risk level."
                />
              </div>
            </div>
          </div>
        </section>

        <section className="bg-green py-5 code-int-area">
          <div className="container">
            <div className="col-md-11 mx-auto text-white">
              <div className="row justify-content-between">
                <div className="col-md-6 py-3 px-md-5 pe-md-5">
                  <h2>Enjoy Hassle-Free Loan Operations when you Use CLARS.</h2>
                  <a
                    href="http://app.useclars.com/"
                    target="_blank"
                    className="btn btn-white-outline atag mt-3"
                  >
                    Get Started
                    <span className="ms-3">{WhiteArrowCircleRight}</span>
                  </a>
                </div>

                <div className="col-md-4 ps-md-5">
                  <img src={bank} alt="Bank" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <FAQSection />
        <section>
          <div className=" mb-0 bg-light ">
            <br />
            <h2 className="text-center mt-2">Powered by </h2>
            <div className="d-flex justify-content-around align-items-center mt-4 mb-4">
              <a
                href="https://prembly.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={prembly}
                  className="img-fluid img-size"
                  alt="Prembly"
                />
              </a>
              <a
                href="https://vfdgroup.com"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-3"
              >
                <img src={vfd} className="img-fluid img-size" alt="VFD" />
              </a>
              <a
                href="https://intelyticslimited.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={intelytic}
                  className="img-fluid img-size"
                  alt="Intelytics"
                />
              </a>
            </div>
          </div>
        </section>
        <FooterComp />
      </div>
    </>
  );
}
