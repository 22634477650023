import { useState } from "react";
import { AddIcon } from "../../assets/svgs";

const AccordionItem = ({ isOpen, handleToggle, title, children }) => {
  return (
    <div className="accordion-item border-bottom mt-3">
      <div
        className="accordion-header d-flex align-items-center"
        onClick={handleToggle}
      >
        <div className="row w-100">
          <div className="col-11">
            <h5 className="text-secondary">{title}</h5>
          </div>
          <div className="col-1 align-items-center">{AddIcon}</div>
        </div>
      </div>
      {isOpen && <div className="accordion-body">{children}</div>}
    </div>
  );
};

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  const faqItems = [
    // {
    //   title: "What is Clars?",
    //   content:
    //     "CLARS elevates lending operations through technology designed to automate and manage collections; creating sustainable financial growth and efficiency for loan providers.",
    // },
    {
      title: "How does Clars handle consent for accessing bank accounts? ",
      content:
        "During the loan application process, borrowers must provide explicit consent for Clars to access their bank accounts. This consent is documented and stored securely to ensure compliance with privacy laws and regulations.",
    },
    {
      title: "What information is required for consent? ",
      content:
        "Borrowers must provide associated unique identification number and consent for Clars to track their bank accounts.",
    },
    {
      title: "Can a borrower withdraw their consent after providing it? ",
      content:
        "Yes, a borrower can withdraw their consent at any time. However, withdrawing consent may impact their loan agreement and could lead to immediate repayment of the outstanding loan amount or other contractual consequences. ",
    },
    {
      title: "What measures are in place to protect the privacy of borrowers? ",
      content:
        "Clars employs robust security measures, including encryption and secure storage, to protect the personal and financial information of borrowers. Consent records are maintained confidentially and accessed only by authorized personnel. ",
    },
    // {
    //   title: "How does Clars track a borrower's bank accounts?  ",
    //   content:
    //     "Clars uses the borrower’s BVN to link and monitor all associated bank accounts, providing a comprehensive view of their financial status. ",
    // },
    // {
    //   title: "What happens if a borrower defaults on a payment? ",
    //   content:
    //     "If a borrower defaults, Clars automatically debits the due amount from any of the borrower’s linked bank accounts. ",
    // },
    // {
    //   title: "How does Clars ensure compliance with financial regulations? ",
    //   content:
    //     "Clars adheres to all relevant financial regulations and privacy laws. We regularly review and update our practices to ensure ongoing compliance and protect the rights of borrowers and their next of kin. ",
    // },
    // {
    //   title: "What legal protections are in place for borrowers? ",
    //   content:
    //     "Borrowers are protected by consent laws and financial regulations that govern lending practices. Clars’ processes are designed to uphold these protections and ensure fair treatment. ",
    // },
    // {
    //   title: "Who can I contact if I have concerns about consent or privacy? ",
    //   content:
    //     "If you have any concerns or questions about consent or privacy, please contact our customer support team. We are committed to addressing your concerns and ensuring your information is handled with the utmost care and security. ",
    // },
  ];

  return (
    <section className="py-5">
      <div className="container">
        <div className="col-md-7 mx-auto">
          <div className="text-center col-md-6 mx-auto mb-5">
            <h2>Let us Answer your questions</h2>
          </div>

          {faqItems.map((item, index) => (
            <AccordionItem
              key={index}
              isOpen={openIndex === index}
              handleToggle={() => handleToggle(index)}
              title={item.title}
            >
              <p className="text-secondary lh-lg">{item.content}</p>
            </AccordionItem>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
