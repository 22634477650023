import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/logo main.svg";
import menu from "../../assets/Hamburger.png";
import share from "../../assets/share.png";
import search from "../../assets/search-normal.svg";
import doc from "../../assets/document.svg";

export default function NavbarComp() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    // Event listeners for scroll and click outside
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <nav
        className={`navbar navbar-expand-lg px-4 p-md-3 fixed-top ${
          isScrolled ? "navbar-scrolled" : ""
        }`}
      >
        <div className="container py-3 col-md-8 mx-auto d-md-flex">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Logo" width="120" />
          </a>
          <img
            src={menu}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            alt="menu"
            className="d-lg-none"
          />
          <div
            className="collapse navbar-collapse justify-content-center px-2"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0 navlist py-2">
              <li className="nav-item nav-item-spacing py-2">
                <a
                  className="nav-link navlist"
                  href="/contact"
                  role="button"
                  aria-expanded="false"
                >
                  Contact
                </a>
              </li>
              <li className="nav-item dropdown nav-item-spacing py-3">
                <button
                  className="nav-link navlist btn btn-link d-flex align-items-center"
                  onClick={toggleDropdown}
                  aria-expanded={isDropdownOpen}
                  style={{
                    border: "none",
                    background: "none",
                    padding: 0,
                  }}
                  ref={dropdownRef}
                >
                  Features
                </button>

                <br />
                {/* SliderIconOne, SliderIconThree, SliderIconTwo, */}
                {isDropdownOpen && (
                  <ul className="dropdown-menu show dp navlist">
                    <li>
                      <a className="dropdown-item" href="#">
                        <img src={doc} alt="" className="me-2" />
                        Loan Collection
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <img src={share} alt="" className="me-2" />
                        Borrower Risk Scoring
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <img src={search} alt="" className="me-2" />
                        Origination (coming soon)
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <img src={search} alt="" className="me-2" />
                        Disbursement (coming soon)
                      </a>
                    </li>
                    <li className="nav-item w-100 btn">
                      <a
                        className="nav-link btn-green-mobile atag"
                        href="http://app.useclars.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Sign In
                      </a>
                    </li>
                  </ul>
                )}
              </li>

              <li className="nav-item d-lg-none btn">
                <a
                  className="nav-link btn-green-mobile atag"
                  href="http://app.useclars.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sign In
                </a>
              </li>
            </ul>
          </div>
          <div className="d-none d-lg-flex">
            <a
              className="btn btn-green px-3 atag"
              href="http://app.useclars.com/"
              target="_blank"
              rel="noreferrer"
            >
              Sign In
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}
