import React from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import NavbarComp from "../components/navbar";
import FooterComp from "../components/footer";

export default function Contact() {
  return (
    <div>
      <NavbarComp />

      <Container className="d-flex flex-column justify-content-center align-items-center contact">
        <Row className="w-100 text-center ">
          <Col xs={12} className="text-center">
            <h4 className="contact-header">Contact Us</h4>
            <p className="contact-paragraph">
              Have inquiries or questions? Fill out the form and we will get
              back to you.
            </p>
          </Col>
        </Row>

        <Row className="w-100 my-4">
          <Col xs={12} md={4} className="mx-auto form mb-4">
            <Form>
              <Form.Group controlId="formFullName" className="mb-3 ">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your full name"
                  className="form"
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  className="form"
                />
              </Form.Group>

              <Form.Group controlId="formMessage" className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Enter your message"
                  className="form"
                />
              </Form.Group>

              <Button
                type="submit"
                className="w-100 rounded-pill btn-green form"
              >
                Send
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>

      <FooterComp />
    </div>
  );
}
