import React from "react";
import heroImg from "../assets/Illustration.png";

const TabletAnimation = () => {
  return (
    <div className="lp-headcap-tablet">
      <div className="lp-headcap-tablet-frame">
        <div className="lp-headcap-tablet-frame-bottom"></div>
        <div className="lp-headcap-tablet-frame-interface">
          <picture>
            <source srcSet={heroImg} media="(min-width: 768px)" />
            <img
              src="/path-to-your-image.svg"
              loading="lazy"
              alt="screenshot of Loanpro's modern lending platform"
            />
          </picture>
        </div>
        <div className="lp-headcap-tablet-frame-face">
          <picture>
            <img src="/path-to-another-image.svg" loading="lazy" alt="" />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default TabletAnimation;
